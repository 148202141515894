var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "480",
            "max-height": "400",
            persistent: "",
            fullscreen: _vm.$vuetify.breakpoint.smAndDown,
          },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "overflow-hidden" },
            [
              _c(
                "v-row",
                { staticClass: "ma-0 ml-4" },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "col-10 px-0 grey--text text--darken-4 text-h6 text-truncate",
                    },
                    [_vm._v(_vm._s(_vm.$t("updateTicket")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "col-2 my-auto",
                      attrs: { "aria-label": "cross", icon: "" },
                      on: {
                        click: function ($event) {
                          return _vm.closeDialog()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [_c("v-divider")],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "mx-4 mt-4",
                  attrs: { "no-gutters": "", justify: "center" },
                },
                [
                  _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      dense: "",
                      required: "",
                      "error-messages": _vm.callerErrors,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.caller.$touch()
                      },
                      blur: function ($event) {
                        return _vm.$v.caller.$touch()
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("span", { staticClass: "red--text" }, [
                              _vm._v(" * "),
                            ]),
                            _vm._v(_vm._s(_vm.$t("userName")) + " "),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.caller,
                      callback: function ($$v) {
                        _vm.caller = $$v
                      },
                      expression: "caller",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "mx-4",
                  attrs: { "no-gutters": "", justify: "center" },
                },
                [
                  _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      dense: "",
                      "hide-details": _vm.caller_phoneErrors.length === 0,
                      "error-messages": _vm.caller_phoneErrors,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.caller_phone.$touch()
                      },
                      blur: function ($event) {
                        return _vm.$v.caller_phone.$touch()
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("span", { staticClass: "red--text" }),
                            _vm._v(_vm._s(_vm.$t("userPhone")) + " "),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.caller_phone,
                      callback: function ($$v) {
                        _vm.caller_phone = $$v
                      },
                      expression: "caller_phone",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "mx-4 mt-4",
                  attrs: { "no-gutters": "", justify: "center" },
                },
                [
                  _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      dense: "",
                      "hide-details": _vm.emailErrors.length === 0,
                      "error-messages": _vm.emailErrors,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.email.$touch()
                      },
                      blur: function ($event) {
                        return _vm.$v.email.$touch()
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("span", { staticClass: "red--text" }),
                            _vm._v(_vm._s(_vm.$t("email")) + " "),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.email,
                      callback: function ($$v) {
                        _vm.email = $$v
                      },
                      expression: "email",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "mx-4 mt-4",
                  attrs: { "no-gutters": "", justify: "center" },
                },
                [
                  _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      dense: "",
                      "hide-details": _vm.contactError.length === 0,
                      "error-messages": _vm.contactError,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.contact.$touch()
                      },
                      blur: function ($event) {
                        return _vm.$v.contact.$touch()
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("span", { staticClass: "red--text" }),
                            _vm._v(_vm._s(_vm.$t("contact_person")) + " "),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.contact,
                      callback: function ($$v) {
                        _vm.contact = $$v
                      },
                      expression: "contact",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "mx-4 mt-4",
                  attrs: { "no-gutters": "", justify: "center" },
                },
                [
                  _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      dense: "",
                      "hide-details": _vm.contact_phoneErrors.length === 0,
                      "error-messages": _vm.contact_phoneErrors,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.contact_phone.$touch()
                      },
                      blur: function ($event) {
                        return _vm.$v.contact_phone.$touch()
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("span", { staticClass: "red--text" }),
                            _vm._v(_vm._s(_vm.$t("contactPhone")) + " "),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.contact_phone,
                      callback: function ($$v) {
                        _vm.contact_phone = $$v
                      },
                      expression: "contact_phone",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "mx-4 mt-4",
                  attrs: { "no-gutters": "", justify: "center" },
                },
                [
                  _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      dense: "",
                      "hide-details": _vm.remarkError.length === 0,
                      "error-messages": _vm.remarkError,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.remarks.$touch()
                      },
                      blur: function ($event) {
                        return _vm.$v.remarks.$touch()
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("span", { staticClass: "red--text" }),
                            _vm._v(_vm._s(_vm.$t("remarks")) + " "),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.remarks,
                      callback: function ($$v) {
                        _vm.remarks = $$v
                      },
                      expression: "remarks",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "mx-4 mt-4",
                  attrs: { "no-gutters": "", justify: "center" },
                },
                [
                  _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      dense: "",
                      "hide-details": _vm.commentErrors.length === 0,
                      "error-messages": _vm.commentErrors,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.comments.$touch()
                      },
                      blur: function ($event) {
                        return _vm.$v.comments.$touch()
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("span", { staticClass: "red--text" }),
                            _vm._v(_vm._s(_vm.$t("comments")) + " "),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.comments,
                      callback: function ($$v) {
                        _vm.comments = $$v
                      },
                      expression: "comments",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "mx-4 mt-2",
                  class: _vm.$vuetify.breakpoint.smAndDown ? "mb-16" : "",
                  attrs: { "no-gutters": "", justify: "center" },
                },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      items: _vm.membersToAddList,
                      "menu-props": { bottom: true, offsetY: true },
                      label: _vm.$t("membersToAdd"),
                      outlined: "",
                      dense: "",
                      multiple: "",
                      "item-text": "mbcode_name",
                      "item-value": "mbcode",
                      "search-input": _vm.searchCountyMembers,
                    },
                    on: {
                      input: function ($event) {
                        _vm.searchCountyMembers = null
                      },
                      "update:searchInput": function ($event) {
                        _vm.searchCountyMembers = $event
                      },
                      "update:search-input": function ($event) {
                        _vm.searchCountyMembers = $event
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "prepend-item",
                        fn: function () {
                          return [
                            _c(
                              "v-list-item",
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    "prepend-inner-icon": "mdi-magnify",
                                    label: _vm.$t("searchMembersToAdd"),
                                    "single-line": "",
                                    "hide-details": "",
                                    flat: "",
                                    dense: "",
                                    variant: "underlined",
                                    "clear-icon": "mdi-close-circle",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.searchCountyMembers,
                                    callback: function ($$v) {
                                      _vm.searchCountyMembers = $$v
                                    },
                                    expression: "searchCountyMembers",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "selection",
                        fn: function (ref) {
                          var attrs = ref.attrs
                          var item = ref.item
                          var parent = ref.parent
                          var selected = ref.selected
                          return [
                            _c(
                              "v-chip",
                              _vm._b(
                                {
                                  attrs: {
                                    size: "small",
                                    density: "compact",
                                    "input-value": selected,
                                  },
                                },
                                "v-chip",
                                attrs,
                                false
                              ),
                              [
                                _c(
                                  "span",
                                  { staticClass: "pr-2 chip-content" },
                                  [_vm._v(" " + _vm._s(item.mbcode_name) + " ")]
                                ),
                                _c(
                                  "v-icon",
                                  {
                                    attrs: { small: "" },
                                    on: {
                                      click: function ($event) {
                                        return parent.selectItem(item)
                                      },
                                    },
                                  },
                                  [_vm._v(" mdi-close-circle ")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.selectedMembersToAdd,
                      callback: function ($$v) {
                        _vm.selectedMembersToAdd = $$v
                      },
                      expression: "selectedMembersToAdd",
                    },
                  }),
                ],
                1
              ),
              !_vm.$vuetify.breakpoint.smAndDown
                ? _c("v-row", { staticClass: "mb-2" }, [_c("v-divider")], 1)
                : _vm._e(),
              !_vm.$vuetify.breakpoint.smAndDown
                ? _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "text-none rounded-lg elevation-0",
                          attrs: {
                            color: "primary",
                            outlined: "",
                            depressed: "",
                            width: "90",
                          },
                          on: { click: _vm.closeDialog },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass:
                            "primary text-none rounded-lg elevation-0",
                          attrs: {
                            width: "90",
                            depressed: "",
                            disabled:
                              _vm.$v.$invalid || _vm.submitButtonClicked,
                          },
                          on: { click: _vm.updateTicketSubmit },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("submit")) + " ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$vuetify.breakpoint.smAndDown
                ? _c(
                    "v-bottom-navigation",
                    {
                      staticClass: "px-2 py-4",
                      attrs: { fixed: "", horizontal: "", height: "70" },
                    },
                    [
                      _c(
                        "v-row",
                        {
                          attrs: { justify: "space-between", "no-gutters": "" },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "mr-2 text-none rounded-lg elevation-0",
                              attrs: {
                                color: "primary",
                                outlined: "",
                                width: "50%",
                              },
                              on: { click: _vm.closeDialog },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "text-none font-weight-regular grey--text rounded-lg elevation-0",
                              attrs: {
                                color: "primary",
                                width: "50%",
                                depressed: "",
                                disabled:
                                  _vm.$v.$invalid || _vm.submitButtonClicked,
                              },
                              on: { click: _vm.updateTicketSubmit },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "white--text text-none" },
                                [_vm._v(_vm._s(_vm.$t("submit")))]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }